<template lang="html">
  <div class="header">


  <div class="group" >
    <label for="companys">Kompanija: </label>
    <select name="type" id="type" v-model="type" @change="onChangeCompany($event)">
       <option  v-for="company in companys" v-bind:key="company.name">{{company.name}}</option>
    </select>
  </div>
</div>
  <div class="validation">
    {{validation}}
  </div>

  <button type="button" name="button" v-if="companyName!=''" @click="submit">Napravi novu evaluaciju</button>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';

export default {
    data(){
      return{
        companyName:"",
        validation:"",
      }
    },
    computed: mapGetters(['companys']),
    methods:{
        ...mapActions(['getCompanies','setCompanyKpi','addNewEval']),
        async submit(){
          this.validation="Obradjuje se zahtev..."
          console.log("COMPANY NAME: ",this.companyName);
          let kpiData={
            companyName:this.companyName
          }
          let res = await this.addNewEval(kpiData);
          if(res){
            this.validation="Uspesno dodata evaluacija"
          }else{
            this.validation="Neuspesno dodata evaluacija..."
          }
        },
        onChangeCompany(event) {
          this.companyName=event.target.value;
        },
    },
    async created(){
    await   this.getCompanies();

    }
}
</script>


<style lang="css" scoped>
.kpi{
  /* display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". ."; */
    border-style: solid;
    border-color: black;
    border-width: thin;
    padding:10px;
    margin-bottom: 5px;
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
.kompetence{
  background-color: #ddddff;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding:10px;
  margin-bottom: 5px;
}
.KompanijskiKpi{
  background-color: #ddffdd;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding:10px;
  margin-bottom: 5px;
}
.header{
  background-color: #ddffdd;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding:10px;
  margin-bottom: 5px;
}
</style>
